<template>
  <div class="calculator">
    <div class="calculator__inner">
      <div class="calculator__col">
        <div class="order-create__head mb-4">
          <div class="order-create__head-line">
            <div class="order-create__header-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_backToDashboard',])"></div>
              <router-link :to="$store.getters.GET_PATHS.dashboard" class="order-create__head-back">
                <LinkBack
                    :value="$t('shippingCost_backToDashboard.localization_value.value')"
                />
              </router-link>
            </div>
          </div>
          <div class="order-create__head-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['calculatorEngraving_calculatorEngraving',])"></div>
            {{ $t('calculatorEngraving_calculatorEngraving.localization_value.value') }}
            <ValueHelper
                class="orange-color"
                :value="countryInTitle.engraving"
                :replaceValue="''"
            />
          </div>
          <div class="order-create__head-txt mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['calculatorEngraving_calculatorEngravingTxt',])"></div>
            {{ $t('calculatorEngraving_calculatorEngravingTxt.localization_value.value') }}
          </div>
        </div>

        <div class="order-create__section">


          <DimensionsBlock
              :calculator="calculator"
          />

          <div class="custom-row">
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_count',])"></div>
              <DefaultInput
                  :label="$t('calculatorEngraving_count.localization_value.value')"
                  :type="'text'"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': calculator.validation.count}"
                  :errorTxt="$t(`${calculator.validationTranslate.count}.localization_value.value`)"
                  :error="calculator.validation.count"
                  v-model="calculator.data.count"
              />
            </div>

            <div class="custom-col custom-col--50 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
              <DefaultSelect
                  :options="engravingCustomOptions"
                  :label="$t('calculatorEngraving_product.localization_value.value')"
                  :otherValue="'calculatorCustomization'"
                  :selected="calculator.data.customization"
                  @change="(val) => {calculator.data.customization = val}"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_differentEngrave',])"></div>
              <DefaultCheckbox
                  :label="$t('calculatorEngraving_differentEngrave.localization_value.value')"
                  v-model="calculator.data.differentEngrave"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_adjustingLayout',])"></div>
              <DefaultCheckbox
                  :label="$t('calculatorEngraving_adjustingLayout.localization_value.value')"
                  v-model="calculator.data.adjustingLayout"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
              <MainButton
                  :value="$t('shippingCost_count.localization_value.value')"
                  @click.native="$emit('shippingCostCalculate')"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
              />
            </div>
          </div>


          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_EmbroideryCalculator',])"></div>
                {{ $t('calculatorEngraving_EmbroideryCalculator.localization_value.value') }}
                <ValueHelper
                    class="orange-color"
                    :value="countryInTitle.embroidery"
                    :replaceValue="''"
                />
              </div>
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_NumberOfStitches',])"></div>
              <DefaultInput
                  :label="$t('calculatorEngraving_NumberOfStitches.localization_value.value')"
                  :type="'text'"
                  :placeholder="''"
                  v-bind:class="{'ui-no-valid': Embroidery.validation.stitches}"
                  :errorTxt="$t(`${Embroidery.validationTranslate.stitches}.localization_value.value`)"
                  :error="Embroidery.validation.stitches"
                  v-model="Embroidery.data.stitches"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity',])"></div>
              <DefaultInput
                  :label="$t('calculatorEngraving_quantity.localization_value.value')"
                  :type="'text'"
                  :placeholder="''"
                  v-bind:class="{'ui-no-valid': Embroidery.validation.quantity}"
                  :errorTxt="$t(`${Embroidery.validationTranslate.quantity}.localization_value.value`)"
                  :error="Embroidery.validation.quantity"
                  v-model="Embroidery.data.quantity"
              />
            </div>
            <div class="custom-col custom-col--50 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
              <DefaultSelect
                  :options="embroideryCustomOptions"
                  :label="$t('calculatorEngraving_product.localization_value.value')"
                  :otherValue="'calculatorCustomization'"
                  :selected="Embroidery.data.customization"
                  @change="(val) => {Embroidery.data.customization = val}"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
              <MainButton
                  :value="$t('shippingCost_count.localization_value.value')"
                  @click.native="$emit('embroideryCostCalculate')"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
              />
            </div>
          </div>


          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_TShirtPrinting',])"></div>
                {{ $t('calculatorEngraving_TShirtPrinting.localization_value.value') }}
                <ValueHelper
                    class="orange-color"
                    :value="countryInTitle.t_shirt_printing"
                    :replaceValue="''"
                />
              </div>
            </div>
          </div>


          <div class="custom-row">
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity',])"></div>
              <DefaultInput
                  :label="$t('calculatorEngraving_quantity.localization_value.value')"
                  :type="'text'"
                  :placeholder="''"
                  v-bind:class="{'ui-no-valid': TShirtPrinting.validation.quantity}"
                  :errorTxt="$t(`${TShirtPrinting.validationTranslate.quantity}.localization_value.value`)"
                  :error="TShirtPrinting.validation.quantity"
                  v-model="TShirtPrinting.data.quantity"
              />
            </div>

            <div class="custom-col custom-col--50 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
              <DefaultSelect
                  :options="dtgPrintingCustomOptions"
                  :label="$t('calculatorEngraving_product.localization_value.value')"
                  :otherValue="'calculatorCustomization'"
                  :selected="TShirtPrinting.data.customization"
                  @change="(val) => {TShirtPrinting.data.customization = val}"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
              <MainButton
                  :value="$t('shippingCost_count.localization_value.value')"
                  @click.native="$emit('tShirtPrintingCostCalculate')"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
              />
            </div>
          </div>


          <template :name="'leather-stamp'">
            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="order-create__head-title"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['calculatorEngraving_LeatherStampCalculator',])"></div>
                  {{ $t('calculatorEngraving_LeatherStampCalculator.localization_value.value') }}
                  <ValueHelper
                      class="orange-color"
                      :value="countryInTitle.leather_stamping"
                      :replaceValue="''"
                  />
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity',])"></div>
                <DefaultInput
                    :label="$t('calculatorEngraving_quantity.localization_value.value')"
                    :type="'text'"
                    :placeholder="''"
                    :maxLength="10"
                    v-bind:class="{'ui-no-valid': LeatherStamp.validation.stampingLine}"
                    :errorTxt="$t(`${LeatherStamp.validationTranslate.stampingLine}.localization_value.value`)"
                    :error="LeatherStamp.validation.stampingLine"
                    v-model="LeatherStamp.data.stampingLine"
                />
              </div>
              <div class="custom-col custom-col--50 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
                <DefaultSelect
                    :options="leatherStampCustomOptions"
                    :label="$t('calculatorEngraving_product.localization_value.value')"
                    :otherValue="'calculatorCustomization'"
                    :selected="LeatherStamp.data.customization"
                    @change="(val) => {LeatherStamp.data.customization = val}"
                />
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
                <MainButton
                    :value="$t('shippingCost_count.localization_value.value')"
                    @click.native="$emit('leatherStampCostCalculate')"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
                />
              </div>
            </div>
          </template>

          <template :name="'photo-printing'">
            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="order-create__head-title"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['calculatorEngraving_PhotoPrintCalculator',])"></div>
                  {{ $t('calculatorEngraving_PhotoPrintCalculator.localization_value.value') }}
                  <ValueHelper
                      class="orange-color"
                      :value="countryInTitle.photo_print"
                      :replaceValue="''"
                  />
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity',])"></div>
                <DefaultInput
                    :label="$t('calculatorEngraving_quantity.localization_value.value')"
                    :type="'text'"
                    :placeholder="''"
                    v-bind:class="{'ui-no-valid': PhotoPrint.validation.quantity}"
                    :errorTxt="$t(`${PhotoPrint.validationTranslate.quantity}.localization_value.value`)"
                    :error="PhotoPrint.validation.quantity"
                    v-model="PhotoPrint.data.quantity"
                />
              </div>
              <div class="custom-col mb-0"></div>
              <div class="custom-col custom-col--50 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
                <DefaultSelect
                    :options="photoPrintCustomOptions"
                    :label="$t('calculatorEngraving_product.localization_value.value')"
                    :otherValue="'calculatorCustomization'"
                    :selected="PhotoPrint.data.customization"
                    @change="(val) => {PhotoPrint.data.customization = val}"
                />
              </div>
              <div class="custom-col custom-col--50 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_productSize',])"></div>
                <DefaultSelect
                    :options="photoPrintSizeOptions"
                    :label="$t('calculatorEngraving_productSize.localization_value.value')"
                    :otherValue="'calculatorCustomization'"
                    :selected="PhotoPrint.data.size"
                    @change="(val) => {PhotoPrint.data.size = val}"
                />
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
                <MainButton
                    :value="$t('shippingCost_count.localization_value.value')"
                    @click.native="$emit('photoPrintCostCalculate')"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
                />
              </div>
            </div>
          </template>



          <template :name="'UV-Printing'">
            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="order-create__head-title"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['calculatorEngraving_UVPrintingCalculator',])"></div>
                  {{ $t('calculatorEngraving_UVPrintingCalculator.localization_value.value') }}
                  <ValueHelper
                      class="orange-color"
                      :value="countryInTitle.uv_print"
                      :replaceValue="''"
                  />
                </div>
              </div>
            </div>

            <DimensionsBlock
                :calculator="UVPrinting"
                :typeDimensions="'cm'"
            />

            <div class="custom-row">
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_count',])"></div>
                <DefaultInput
                    :label="$t('calculatorEngraving_count.localization_value.value')"
                    :type="'text'"
                    :placeholder="'e.g. 10'"
                    v-bind:class="{'ui-no-valid': UVPrinting.validation.count}"
                    :errorTxt="$t(`${UVPrinting.validationTranslate.count}.localization_value.value`)"
                    :error="UVPrinting.validation.count"
                    v-model="UVPrinting.data.count"
                />
              </div>

              <div class="custom-col custom-col--50 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
                <DefaultSelect
                    :options="UVPrintingCustomOptions"
                    :label="$t('calculatorEngraving_product.localization_value.value')"
                    :otherValue="'calculatorCustomization'"
                    :selected="UVPrinting.data.customization"
                    @change="(val) => {UVPrinting.data.customization = val}"
                />
              </div>
            </div>

            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCost_count',])"></div>
                <MainButton
                    :value="$t('shippingCost_count.localization_value.value')"
                    @click.native="$emit('UVPrintingCostCalculate')"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
                />
              </div>
            </div>
          </template>

        </div>
      </div>
      <div class="calculator__col">
<!--            v-if="Object.keys(result).length > 0"-->
        <CalculatorResult
            v-if="true"
            :result="result"
            :medias="medias"
            :files="files"
        />
        <DotsShadowPreloader
            v-else-if="$store.getters.getOrdersFBMBtn"
        />
        <NoResult
            v-else
            :title="$t('common_noResult.localization_value.value')"
            :text="$t('common_noResultSeems.localization_value.value')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
import CalculatorResult from "./CalculatorResult/CalculatorResult";
import NoResult from "../../../../../coreComponents/NoResult/NoResult";
import DimensionsBlock from "../../chunks/DimensionsBlock/DimensionsBlock";
import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "CalculatorBlock",
  components: {
    ValueHelper,
    DefaultSelect,
    DefaultInput,
    DefaultCheckbox,
    DotsShadowPreloader,
    DimensionsBlock,
    LinkBack,
    MainButton,
    CalculatorResult,
    NoResult,
  },

  props: {
    calculator: Object,
    Embroidery: Object,
    TShirtPrinting: Object,
    LeatherStamp: Object,
    PhotoPrint: Object,
    UVPrinting: Object,
    result: Object,
    medias: Array,
    files: Array,
  },

  data() {
    return {
      engravingCustomOptions: [],
      embroideryCustomOptions: [],
      dtgPrintingCustomOptions: [],
      leatherStampCustomOptions: [],
      photoPrintSizeOptions: [],
      photoPrintCustomOptions: [],
      UVPrintingCustomOptions: [],

      countryInTitle: {},
      // colors: [],
      // qualities: [],
    }
  },

  mounted() {
    this.$store.dispatch('getTShirtCalcCreate').then(response => {

      let data = this.getRespData(response)?.customization_offers

      this.engravingCustomOptions = data?.engraving
      this.embroideryCustomOptions = data?.embroidery
      this.dtgPrintingCustomOptions = data?.dtg_printing
      this.UVPrintingCustomOptions = data?.uv_printing

      this.leatherStampCustomOptions = data?.leather_stamping
      this.photoPrintSizeOptions = data?.photo_printing_size
      this.photoPrintCustomOptions = data?.photo_printing

      this.countryInTitle = this.getRespData(response)?.country_in_title

      // this.colors = []
      // Object.keys(this.getRespData(response)?.colorType).map((item, index) => {
      //   this.colors.push({
      //     value: item,
      //     translation: this.getRespData(response)?.colorType[item],
      //   })
      //   if(index === 0) this.TShirtPrinting.data.color = this.colors[0].value
      // })

      // this.qualities = []
      // Object.keys(this.getRespData(response)?.type).map((item, index) => {
      //   this.qualities.push({
      //     value: item,
      //     translation: this.getRespData(response)?.type[item],
      //   })
      //   if(index === 0) this.TShirtPrinting.data.quality = this.qualities[0].value
      // })

    })
  },

  methods: {
    showResult(val) {
      this.result = val
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../../scss/mixins/mixins";
@import "../../../../../../scss/colors";

.orange-color {
  color: #cd9e64;
}

.calculator {
  margin-top: 30px;

  @include for-1600 {
    margin-bottom: 15px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    width: 50%;
    padding: 48px;

    &:first-child {
      background: white;
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border: 1px solid $borderBrown;
    }

    @include for-1600 {
      padding: 25px 20px;
    }

    @include for-1200 {
      width: 100%;
      padding: 25px 15px;
    }
  }

  .empty-list {
    min-height: calc(100vh - 310px);
  }

}

</style>