<template>
 <div class="fragment">
<!--   <div class="section-label"-->
<!--        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--     <div class="admin-edit" @click="editTranslate(['shippingCost_productInformation',])"></div>-->
<!--     {{$t('shippingCost_productInformation.localization_value.value')}}-->
<!--   </div>-->

   <div class="custom-row"
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
     <div class="admin-edit" @click="editTranslate([
         // 'common_weightKg',
         // 'consolidations_heightCm',
         'consolidations_widthMm',
         'consolidations_lengthMm',
         'packaging_Width',
         'packaging_Length',
         ])"></div>
<!--     <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50">-->
<!--       <DefaultInput-->
<!--           :label="$t('common_weightKg.localization_value.value')"-->
<!--           :type="'text'"-->
<!--           :placeholder="'e.g. 10'"-->
<!--           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.weight}"-->
<!--           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.weight}.localization_value.value`)"-->
<!--           :error="calculator.data.Dimensions.validation.weight"-->
<!--           :disabled="$store.getters.getConsolidationBtn"-->
<!--           v-model="calculator.data.Dimensions.data.weight"-->
<!--           @input.native="acceptNumber($event, 'weight')"-->
<!--       />-->
<!--     </div>-->
<!--     <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50">-->
<!--       <DefaultInput-->
<!--           :label="$t('consolidations_heightCm.localization_value.value')"-->
<!--           :type="'text'"-->
<!--           :placeholder="'e.g. 10'"-->
<!--           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.height}"-->
<!--           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.height}.localization_value.value`)"-->
<!--           :error="calculator.data.Dimensions.validation.height"-->
<!--           :disabled="$store.getters.getConsolidationBtn"-->
<!--           v-model="calculator.data.Dimensions.data.height"-->
<!--           @input.native="acceptNumber($event, 'height')"-->
<!--       />-->
<!--     </div>-->
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="typeDimensions === 'mm' ? $t('consolidations_widthMm.localization_value.value') : $t('packaging_Width.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.width}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.width}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.width"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.width"
           @input.native="acceptNumber($event, 'width')"
       />
     </div>
     <div class="custom-col custom-col--25 custom-col--md-50">
       <DefaultInput
           :label="typeDimensions === 'mm' ? $t('consolidations_lengthMm.localization_value.value') : $t('packaging_Length.localization_value.value')"
           :type="'text'"
           :placeholder="'e.g. 10'"
           v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.dimensionLength}"
           :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
           :error="calculator.data.Dimensions.validation.dimensionLength"
           :disabled="$store.getters.getConsolidationBtn"
           v-model="calculator.data.Dimensions.data.dimensionLength"
           @input.native="acceptNumber($event, 'length')"
       />
     </div>

   </div>
 </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "DimensionsBlock",

  components: {
    DefaultInput
  },

  props: {
    calculator: Object,
    typeDimensions: {
      type: String,
      default: 'mm'
    },
  },

  methods: {
    acceptNumber(event, type) {
      switch (type) {
        case 'weight':
          this.calculator.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'length':
          this.calculator.data.Dimensions.data.dimensionLength = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'width':
          this.calculator.data.Dimensions.data.width = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'height':
          this.calculator.data.Dimensions.data.height = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        default:
          break
      }

      /**
       * Remove Tenths
       */
      if(this.calculator.data.Dimensions.data.weight.indexOf('.') > -1) this.calculator.data.Dimensions.data.weight = this.calculator.data.Dimensions.data.weight.split('.')[0]
      if(this.calculator.data.Dimensions.data.dimensionLength.indexOf('.') > -1) this.calculator.data.Dimensions.data.dimensionLength = this.calculator.data.Dimensions.data.dimensionLength.split('.')[0]
      if(this.calculator.data.Dimensions.data.width.indexOf('.') > -1) this.calculator.data.Dimensions.data.width = this.calculator.data.Dimensions.data.width.split('.')[0]
      if(this.calculator.data.Dimensions.data.height.indexOf('.') > -1) this.calculator.data.Dimensions.data.height = this.calculator.data.Dimensions.data.height.split('.')[0]
    }
  }
}
</script>

<style scoped>

</style>