var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"custom-row",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
         // 'common_weightKg',
         // 'consolidations_heightCm',
         'consolidations_widthMm',
         'consolidations_lengthMm',
         'packaging_Width',
         'packaging_Length',
         ])}}}),_c('div',{staticClass:"custom-col custom-col--25 custom-col--md-50"},[_c('DefaultInput',{class:{'ui-no-valid': _vm.calculator.data.Dimensions.validation.width},attrs:{"label":_vm.typeDimensions === 'mm' ? _vm.$t('consolidations_widthMm.localization_value.value') : _vm.$t('packaging_Width.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.$t(`${_vm.calculator.data.Dimensions.validationTranslate.width}.localization_value.value`),"error":_vm.calculator.data.Dimensions.validation.width,"disabled":_vm.$store.getters.getConsolidationBtn},nativeOn:{"input":function($event){return _vm.acceptNumber($event, 'width')}},model:{value:(_vm.calculator.data.Dimensions.data.width),callback:function ($$v) {_vm.$set(_vm.calculator.data.Dimensions.data, "width", $$v)},expression:"calculator.data.Dimensions.data.width"}})],1),_c('div',{staticClass:"custom-col custom-col--25 custom-col--md-50"},[_c('DefaultInput',{class:{'ui-no-valid': _vm.calculator.data.Dimensions.validation.dimensionLength},attrs:{"label":_vm.typeDimensions === 'mm' ? _vm.$t('consolidations_lengthMm.localization_value.value') : _vm.$t('packaging_Length.localization_value.value'),"type":'text',"placeholder":'e.g. 10',"errorTxt":_vm.$t(`${_vm.calculator.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`),"error":_vm.calculator.data.Dimensions.validation.dimensionLength,"disabled":_vm.$store.getters.getConsolidationBtn},nativeOn:{"input":function($event){return _vm.acceptNumber($event, 'length')}},model:{value:(_vm.calculator.data.Dimensions.data.dimensionLength),callback:function ($$v) {_vm.$set(_vm.calculator.data.Dimensions.data, "dimensionLength", $$v)},expression:"calculator.data.Dimensions.data.dimensionLength"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }