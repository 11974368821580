import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function PhotoPrint() {

    this.checkValid = checkValid

    this.data = {
        isPhotoPrint: false,
        quantity: '',
        File: new Files({
            maxCountFiles: 5,
            maxSizeFiles: 3
        }),
        size: null,
        resultCost: '',
        customization: null,
    }

    this.validation = {
        quantity: false,
    }

    this.validationTranslate = {
        quantity: '',
    }

    this.validationTxt = {
        quantity: false,
    }

    /**
     * Getters
     */
    this.getQuantity = () => {
        return this.data.quantity
    }
    this.getCustomization = () => {
        return this.data.customization
    }
    this.getSize = () => {
        return this.data.size
    }

    /**
     * Setters
     */
    this.setQuantity = (val) => {
        this.data.quantity = val
    }
    this.setCustomization = (val) => {
        this.data.customization = val
    }
    this.setSize = (val) => {
        this.data.size = val
    }
}

/**
 * Functions
 */

PhotoPrint.prototype.dataValidation = function() {
    let validationItems = {
        quantity: this.getQuantity(),
    }

    let validationOptions = {
        quantity: {type: ['numeric', 'empty']},
    }

    return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

PhotoPrint.prototype.prepareData = function() {
    return {
        'offer': this.getCustomization() ? this.getCustomization()?.id : null,
        'quantity': this.getQuantity(),
        'size_offer_id': this.getSize() ? this.getSize()?.id : null,
    }
}