<template>
  <div class="fragment">
    <div class="order-create__head-title"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['shippingCost_calculatingResult'])"></div>
      {{$t('shippingCost_calculatingResult.localization_value.value')}}
    </div>

    <div class="site-table-mobile__piece custom-col">
      {{$t('shippingCost_total.localization_value.value')}}

      <span v-if="result.engraving_cost">
        ${{result.engraving_cost}}
      </span>
      <span v-if="result.rate">
        ${{result.rate}}
      </span>

      <span class="mt-2 d-flex">
        {{ $t('calculatorEngraving_textInfo.localization_value.value') }}
      </span>

    </div>

<!--    <div class="custom-col block-table__content"-->
<!--         v-if="Object.keys(ordersInbound.data.Documents.data.downloadFiles).length > 0"-->
<!--    >-->
<!--      <DownloadFileBlock-->
<!--          v-for="(item, index) in ordersInbound.data.Documents.data.downloadFiles"-->
<!--          :key="index"-->
<!--          :file="item"-->
<!--          :optionDelete="false"-->
<!--      />-->
<!--    </div>-->

    <div class="custom-row" v-if="files.length > 0">
      <div class="custom-col">
        Images
      </div>
      <div class="custom-col custom-col--33 custom-col--md-50 cursor-pointer"
           v-for="(item, index) in files" :key="index"
           @click="show(index)">
        <img :src="`data:image/png;base64,` + item.file"
             style="max-width: 100%; max-height: 130px;"
             alt="img"
        >
      </div>
    </div>

    <div class="custom-row" v-if="medias.length > 0">
      <div class="custom-col" v-if="showTitleVideo(medias)">
        Video
      </div>
      <template
          v-for="(item, index) in medias">
        <div class="custom-col custom-col--33 custom-col--md-50"
             :key="index"
             v-if="item.media_url && item.media_url.length > 0">
          <iframe width="100%" height="150" :src="item.media_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </template>
    </div>


    <div class="custom-row" v-if="result.engraving_cost || result.rate">
      <div class="custom-col">
        <div> {{$t('shippingCost_downloadImages.localization_value.value')}}</div>
        <a class="table-link btn-style" href="https://drive.google.com/drive/u/6/folders/1bkDHYSoQQ_5NJdVxNHANUchV2ZO77V9B" target="_blank">Google Drive</a>
      </div>
    </div>



    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
  import Lightbox from "vue-easy-lightbox";

  export default {
    name: "CalculatorResult",
    components: {
      // DownloadFileBlock,
      Lightbox,

    },

    mixins: [mixinDetictingMobile],

    props: {
      result: Object,
      medias: Array,
      files: Array,
    },

    computed: {

    },

    data(){
      return{
        imgs: ['https://images.prom.ua/5067440078_w640_h640_kartochnaya-igra-the.jpg','https://www.youtube.com/watch?v=2grN2ehegSs&t=1&ab_channel=DeepMemories'],
        visible: false,
        index: 0,
      }
    },

    methods: {
      // calcTotal(item) {
      //   return (+(item.data?.shipmentCost || item.data?.price || 0) + +(item.data?.fee || 0)).toFixed(2)
      // }

      showTitleVideo(medias) {
        let show = false
        medias.forEach(item => {
          if(item.media_url.length > 0) {
            show = true
          }
        })
        return show
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        }).catch(error => this.createErrorLog(error))
      },

      handleHide() {
        this.visible = false
      },

      show(index) {
        console.log(index);
        this.imgs = this.files.map(item => {return `data:image/png;base64,${item.file}`})
        console.log(this.imgs);
        this.visible = true
        this.index = index
      },
    }
  }
</script>
<style lang="scss" scoped>
@import "../../../../../../../scss/colors";

  .table-ico--large {
    width: 60px;
    margin: 0 auto;
  }

.table-ico--mh32 {
  max-height: 32px;

  img {
    max-height: 32px;
  }
}

  .color-red {
    color: $red;
  }
  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-left: 10px;
    min-width: 135px;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    text-align: right;
    padding-right: 10px;
  }

.small-table td{
  white-space: initial;
}

.error-style{
  min-width: 250px;
  display: block;
}

</style>