<template>
  <CalculatorBlock
      :calculator="calculator"
      :Embroidery="Embroidery"
      :TShirtPrinting="TShirtPrinting"
      :LeatherStamp="LeatherStamp"
      :PhotoPrint="PhotoPrint"
      :UVPrinting="UVPrinting"
      :result="result"
      :medias="medias"
      :files="files"
      @shippingCostCalculate="shippingCostCalculate"
      @embroideryCostCalculate="embroideryCostCalculate"
      @tShirtPrintingCostCalculate="tShirtPrintingCostCalculate"
      @photoPrintCostCalculate="photoPrintCostCalculate"
      @leatherStampCostCalculate="leatherStampCostCalculate"
      @UVPrintingCostCalculate="UVPrintingCostCalculate"
  />
</template>

<script>
  import CalculatorBlock from "./CalculatorBlock/CalculatorBlock";
  import {CalculatorEngraving} from "@/components/modules/CalculatorEngravingModule/models/CalculatorEngraving";
  import {Embroidery} from "@/components/modules/CalculatorEngravingModule/models/Embroidery";
  import {TShirtPrinting} from "@/components/modules/CalculatorEngravingModule/models/TShirtPrinting";
  import {PhotoPrint} from "@/components/modules/CalculatorEngravingModule/models/PhotoPrint";
  import {LeatherStamp} from "@/components/modules/CalculatorEngravingModule/models/LeatherStamp";
  import {UVPrinting} from "@/components/modules/CalculatorEngravingModule/models/UVPrinting";

  export default {
    name: "Calculator",

    components: {
      CalculatorBlock,
    },

    created() {
      this.initCalc()
    },

    data() {
      return {
        calculator: new CalculatorEngraving(),
        Embroidery: new Embroidery(),
        TShirtPrinting: new TShirtPrinting(),
        LeatherStamp: new LeatherStamp(),
        PhotoPrint: new PhotoPrint(),
        UVPrinting: new UVPrinting(),
        result: {},
        medias: [],
        files: [],
      }
    },

    methods: {
      initCalc() {
        // this.$store.dispatch('getShippingCostCalcCreate').then(response => {
        //   this.createData = this.getRespData(response)
        //
        //   //get states data
        //   let statesData = this.createData?.states[Object.keys(this.createData?.states).find(Boolean)]
        //
        //   for (const [key, value] of Object.entries(statesData)) {
        //     this.states.push({
        //       'key': key,
        //       'value': value
        //     })
        //   }
        //
        //   // console.log('calc init', this.states);
        // }).catch(error => this.createErrorLog(error))
        //
        // this.$store.dispatch('fetchCountries').then(() => {
        //   this.countries = this._.toArray(this.$store.getters.getCountries).sort((x,y) => {
        //     return x.translationStorage[this.$store.getters.GET_LANG]?.name.localeCompare(y.translationStorage[this.$store.getters.GET_LANG]?.name, this.$store.getters.GET_LANG)
        //   })
        //   this.countriesUK = this._.toArray(this.$store.getters.getCountries).filter(item => {
        //     return item.id === UNITED_KINGDOM_ID
        //   })
        //   //sort countries list to place United States first in array and keep other countries unchanged
        //   this.countries = this.countries.sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })
        //
        //   this.$watch('language', (newVal) => {
        //     this.countries = this.countries.sort((x,y) => {
        //       return x.translationStorage[newVal]?.name.localeCompare(y.translationStorage[newVal]?.name, newVal)
        //     }).sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })
        //   })
        //
        // })

      },

      // validateFranceZip() {
      //   if(this.calculator.getCountry()?.id === 74 && this.calculator.getPostalCode() + '' === '98000') {
      //     this.openNotify('error', 'common_notCountryWithZip')
      //     return false
      //   }
      //   return true
      // },

      shippingCostCalculate() {
        if (!this.calculator.dataValidation()) return

        let prepareData = this.calculator.prepareData()

        this.result = []

        this.$store.dispatch('getEngravingCalcResult', prepareData).then(response => {
          this.setResponse(response)

          if(this.calculator.data.customization?.id && this.calculator.data.customization?.product_id ) {
            this.getMedia(this.calculator.data.customization.product_id)
          } else {
            this.medias = []
            this.files = []
          }

        }).catch(error => this.createErrorLog(error))
      },

      UVPrintingCostCalculate() {
        if (!this.UVPrinting.dataValidation()) return

        let prepareData = this.UVPrinting.prepareData()

        this.result = []

        this.$store.dispatch('getUVPrintingCalcResult', prepareData).then(response => {
          this.setResponse(response)

          if(this.UVPrinting.data.customization?.id && this.UVPrinting.data.customization?.product_id ) {
            this.getMedia(this.UVPrinting.data.customization.product_id)
          } else {
            this.medias = []
            this.files = []
          }

        }).catch(error => this.createErrorLog(error))
      },

      embroideryCostCalculate() {
        if (!this.Embroidery.dataValidation()) return

        let prepareData = this.Embroidery.prepareData()

        this.result = []

        this.$store.dispatch('getEmbroideryCalcResult', prepareData).then(response => {
          this.setResponse(response)

        if(this.Embroidery.data.customization?.id && this.Embroidery.data.customization?.product_id ) {
          this.getMedia(this.Embroidery.data.customization.product_id)
        } else {
          this.medias = []
          this.files = []
        }

        }).catch(error => this.createErrorLog(error))
      },

      tShirtPrintingCostCalculate() {
        if (!this.TShirtPrinting.dataValidation()) return

        let prepareData = this.TShirtPrinting.prepareData()

        this.result = []

        this.$store.dispatch('getTShirtCalcResult', prepareData).then(response => {
          this.setResponse(response)

          if(this.TShirtPrinting.data.customization?.id && this.TShirtPrinting.data.customization?.product_id ) {
            this.getMedia(this.TShirtPrinting.data.customization.product_id)
          } else {
            this.medias = []
            this.files = []
          }

        }).catch(error => this.createErrorLog(error))
      },

      photoPrintCostCalculate() {
        if (!this.PhotoPrint.dataValidation()) return

        let prepareData = this.PhotoPrint.prepareData()

        this.result = []

        this.$store.dispatch('getPhotoPrintCalcResult', prepareData).then(response => {
          this.setResponse(response)

          if(this.PhotoPrint.data.customization?.id && this.PhotoPrint.data.customization?.product_id ) {
            this.getMedia(this.PhotoPrint.data.customization.product_id)
          } else {
            this.medias = []
            this.files = []
          }

        }).catch(error => this.createErrorLog(error))
      },

      leatherStampCostCalculate() {
        if (!this.LeatherStamp.dataValidation()) return

        let prepareData = this.LeatherStamp.prepareData()

        this.result = []

        this.$store.dispatch('getLeatherStampCalcResult', prepareData).then(response => {
          this.setResponse(response)

          if(this.LeatherStamp.data.customization?.id && this.LeatherStamp.data.customization?.product_id ) {
            this.getMedia(this.LeatherStamp.data.customization.product_id)
          } else {
            this.medias = []
            this.files = []
          }

        }).catch(error => this.createErrorLog(error))
      },


      setResponse(response) {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.result = this.getRespData(response)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      },

      getMedia(productId) {
        this.$store.dispatch('getProductMedia', productId).then(response => {
          this.medias = this.getRespData(response).medias

          let files = []
          if(this.getRespData(response)?.files) {
            Object.keys(this.getRespData(response).files).map(item => {
              files.push({
                file: this.getRespData(response).files[item]
              })
            })
          }

          this.files = files
        })
      }
    }

  }
</script>

<style scoped>

</style>